export default {
  adminLogin: {
    description:
      "Accès aux administrateurs permettant la gestion du contenu de l'application du programme HORS-PISTE",
    title: 'Connexion aux administrateurs',
  },
  appAccessUserImport: {
    title: (schoolYear: number) =>
      `Importation d'utilisateurs pour ${schoolYear}-${schoolYear + 1}`,
    importFailed: 'Certaines lignes sont invalides',
    importSuccess: 'Tous les utilisateurs ont été importés avec succès',
  },
  tooltip: {
    permanentCode: `Le code permanent est un code d'identification personnel unique utilisé dans les établissements scolaires. Il est composé de 12 caractères : les 3 premières lettres du nom, la première lettre du prénom suivi de 8 chiffres (p.ex. THED33333333). Vous pouvez trouver le code sur un ancien bulletin ou encore dans le dossier personnel de l'enfant sur le portail utilisé par l'école.`,
  },
  survey: {
    title: 'Questionnaires',
  },
  appAccessData: {
    title: 'Données',
  },
  downloadUserConsents: {
    title: 'Consentement',
    export: 'Exporter les consentements',
  },
  appAccessSchools: {
    title: 'Données par école',
  },
  appAccessSchoolData: {
    title: 'École',
  },
  appAccessLogin: {
    expedition: {
      birthDate: 'Date de naissance',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      school: 'École',
      hasChild:
        'Est-ce que vous avez un enfant qui participe aussi à la recherche ?',
      childFirstName: "Prénom de l'enfant",
      childLastName: "Nom de famille de l'enfant",
      childBirthDate: "Date de naissance de l'enfant",
    },
    frqsc: {
      birthDate: 'Date de naissance',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      school: 'École',
      hasChild:
        'Est-ce que vous avez un enfant qui participe aussi à la recherche ?',
      childFirstName: "Prénom de l'enfant",
      childLastName: "Nom de famille de l'enfant",
      childBirthDate: "Date de naissance de l'enfant",
    },
    expeditionPlus: {
      animationTownName: "Ville du lieu de l'animation",
      birthDate: 'Date de naissance',
      firstName: 'Prénom',
      institution: 'CI(U)SSS',
      lastName: 'Nom de famille',
      hasChild:
        'Est-ce que vous avez un enfant qui participe aussi à la recherche ?',
      childFirstName: "Prénom de l'enfant",
      childLastName: "Nom de famille de l'enfant",
      childBirthDate: "Date de naissance de l'enfant",
    },
    logBook: {
      description:
        "Pour quel numéro d'atelier venez-vous compléter un journal de bord ?",
      survey: 'Atelier',
      noSurveyWarning:
        'Liste de questionnaires manquants. Veuillez réessayer plus tard.',
    },
    parent: {
      permanentCode: 'Code permanent',
      school: 'École',
      unknownPermanentCode: 'Je ne connais pas le code permanent de mon enfant',
    },
    school: {
      accessCode: "Code d'accès",
      school: 'École',
    },
    student: {
      permanentCode: 'Code permanent',
      school: 'École',
    },
  },
  appAccesses: {
    create: {
      title: "Création de l'accès",
    },
    createFailed: "L'accès n'a pas pu être créé",
    createSuccess: "L'accès a été créé avec succès",
    deleteFailed: "L'accès n'a pas pu être supprimé",
    deleteSuccess: "L'accès a été supprimé avec succès",
    edit: {
      title: "Modification de l'accès",
    },
    form: {
      connectionTypeRequired:
        'Cette option requiert un type de connexion sélectionné',
      optionNotAvailableForLogBook:
        "Cette option n'est pas disponible pour les journaux de bord",
      optionNotAvailableForExpeditionPlus:
        "Cette option n'est pas disponible pour Expédition+",
      pageDisplayName: "Nom d'affichage de la page",
      parentConsentPageDisplayName:
        "Nom d'affichage de la page de consentement parental",
      schoolConsentPageDisplayName:
        "Nom d'affichage de la page de consentement pour l'école",
      hasSecondIterationRequired: 'Cette option requiert un temps 2',
      advancedSettings: 'Paramètres avancés',
      allowUserCreationOnLogin: "Permettre l'auto-création d'élèves",
      alternatingProtocol: "Protocole d'alternance",
      connectionType: 'Type de connexion',
      consentForms: 'Formulaires de consentement',
      associatedAppAccess: 'Accès associé',
      enterLinkHere: 'Entrez le lien ici',
      goToNextIterationAutomatically:
        'Passage au prochain temps automatique (4 semaines après la dernière connexion, le temps sera changé au prochain temps)',
      hasParentalConsent: 'Consentement par le parent',
      hasSchoolAccessibleConsent: "Consentement accessible pour l'école",
      hasSecondIteration: 'Permettre le temps 2',
      hasThirdIteration: 'Permettre le temps 3',
      mainLink: 'Lien principal',
      mainPage: 'Page principale',
      surveys:
        "Questionnaires (Sélection multiple permet d'alterner entre les questionnaires)",
      surveyTitle: 'Questionnaires',
      multipleSurveyWarning:
        'Plusieurs questionnaires sont sélectionnés. Le questionnaire affiché sera alterné entre les questionnaires sélectionnés. Cette alternance se fera à chaque connexion.',
      name: 'Nom',
      displayName: "Nom d'affichage",
      page: 'Page',
      parentConsent: 'Consentement parental',
      parentConsentPage: 'Page du consentement parental',
      parentConsentPageLink: 'Lien du consentement parental',
      protocol: 'Protocole',
      schoolConsent: "Consentement pour l'école",
      schoolConsentLink: "Lien du consentement pour l'école",
      schoolConsentPage: "Page du consentement pour l'école",
      schoolType: 'Niveau scolaire',
      theme: 'Thème',
      validateUserOnConnection: 'Validation de connexion',
      endOfSchoolYear: "Fin de l'année scolaire",
    },
    title: 'Accès',
    updateFailed: "L'accès n'a pas pu être modifié",
    updateSuccess: "L'accès a été modifié avec succès",
  },
  configuration: {
    consentForms: 'Formulaires de consentement',
    pages: 'Pages',
    schools: 'Écoles',
    sections: 'Sections',
    themes: 'Thèmes',
    title: 'Configuration',
  },
  connectionTypes: {
    expedition_parent: 'Expédition pour le parent',
    expedition_child: 'Expédition pour le jeune',
    expedition_plus_parent: 'Expédition+ pour le parent',
    expedition_plus_child: 'Expédition+ pour le jeune',
    frqsc_parent: 'FRQSC pour le parent',
    frqsc_child: 'FRQSC pour le jeune',
    logbook: 'Journal de bord',
    student: 'Élève',
  },
  consentForm: {
    acceptConsentForm: "J'accepte librement de participer à ce sondage",
    acceptConsentFormForChild:
      "J'accepte que mon enfant participe à ce sondage",
    address: 'Adresse (Facultatif)',
    denyConsentForm: 'Je ne veux pas participer à ce sondage',
    phoneNumber: 'Numéro de téléphone (Facultatif)',
    consentFormRead: "J'ai lu et compris le formulaire de consentement ci-haut",
    consentFormsRead:
      "J'ai lu et compris les formulaires de consentement ci-haut",
    acceptParentConsentForm:
      "J'ai lu et compris le formulaires de consentement ci-haut, et j'accepte de participer à ce sondage",
  },
  consentForms: {
    create: {
      title: "Création d'un formulaire de consentement",
    },
    createFailed: "Le formulaire de consentement n'a pas pu être créé",
    createSuccess: 'Le formulaire de consentement a été créé avec succès',
    deleteFailed: "Le formulaire de consentement n'a pas pu être supprimé",
    deleteSuccess: 'Le formulaire de consentement a été supprimé avec succès',
    edit: {
      title: 'Modification du formulaire de consentement',
    },
    form: {
      ageConsent: 'Âge de consentement',
      beforeAgeConsentHtml:
        "Consentement avant l'âge de consentement légal spécifié (Pour le parent)",
      consentByAge: 'Consentement par âge',
      consentHtml: 'Consentement',
      consentHtmlWithConsentByAge: 'Consentement du parent au besoin',
      equalAndAfterAgeConsentHtml:
        "Consentement durant et après l'âge de consentement spécifié (Pour le jeune)",
      name: 'Nom',
      participationTypeId: 'Type de participation',
    },
    updateFailed: "Le formulaire de consentement n'a pas pu être modifié",
    updateSuccess: 'Le formulaire de consentement a été modifié avec succès',
  },
  form: {
    code: 'Code',
    description: 'Description',
    name: 'Nom',
    parentPage: 'Page parent',
    participationType: 'Type de participation',
    password: 'Mot de passe',
    schoolType: 'Niveau scolaire',
    section: 'Section',
    sequenceNumber: 'Ordre',
    title: 'Title',
    username: "Nom de l'utilisateur",
  },
  general: {
    continueAnyway: 'Continuer quand même',
    resumeSession: 'Reprendre la session',
    returnToSurvey: 'Retourner au questionnaire',
    cancel: 'Annuler',
    fillIn: 'Remplir',
    leave: 'Quitter',
    login: 'Se connecter',
    logout: 'Se déconnecter',
    no: 'Non',
    none: 'Aucun',
    openUserOptions: "Ouvrir les options d'utilisateur",
    previous: 'Précédent',
    save: 'Enregistrer',
    unexpectedError:
      "Désolé, une erreur est survenue. Veuillez revenir à l'accueil ou réessayer plus tard",
    yes: 'Oui',
  },
  pages: {
    createFailed: "La page n'a pas pu être créée",
    createSuccess: 'La page a été créée avec succès',
    deleteFailed: "La page n'a pas pu être supprimée",
    deleteSuccess: 'La page a été supprimée avec succès',
    form: {
      name: 'Nom',
      description: 'Description',
      parentPage: 'Page parent',
      section: 'Section',
      sequenceNumber: 'Ordre',
      theme: 'Thème',
      title: 'Titre',
    },
    updateFailed: "La page n'a pas pu être modifiée",
    updateSuccess: 'La page a été modifiée avec succès',
  },
  accessUser: {
    createFailed: "L'utilisateur n'a pas pu être créé",
    createSuccess: "L'utilisateur a été créé avec succès",
    deleteFailed: "L'utilisateur n'a pas pu être supprimé",
    deleteSuccess: "L'utilisateur a été supprimé avec succès",
    form: {
      firstName: 'Prénom',
      lastName: 'Nom',
      institutionName: 'Nom du CI(U)SSS',
      animationTownName: "Ville du lieu de l'animation",
      permanentCode: 'Code permanent',
      birthDate: 'Date de naissance',
      schoolLevel: 'Niveau scolaire',
      schoolProgram: 'Programme scolaire',
      groupName: 'Groupe-Classe',
      phoneNumber: 'Numéro de téléphone',
      address: 'Adresse',
      iteration: 'Temps',
      consentGiven: 'Consentement accordé',
      consentDenied: 'Consentement refusé',
    },
    info: {
      firstName: 'Prénom',
      lastName: 'Nom',
      permanentCode: 'Code permanent',
      birthDate: 'Date de naissance',
      schoolLevel: 'Niveau scolaire',
      schoolProgram: 'Programme scolaire',
      groupName: 'Groupe-Classe',
      phoneNumber: 'Numéro de téléphone',
      address: 'Adresse',
      iteration: 'Temps',
      consentGiven: 'Consentement accordé',
      consentDenied: 'Consentement refusé',
      schoolLevelName: 'Niveau scolaire',
      schoolName: 'École',
      schoolProgramName: 'Programme scolaire',
      institutionName: 'Nom du CI(U)SSS',
      animationTownName: "Ville du lieu de l'animation",
      age: 'Âge',
      lastLoginAt: 'Dernière connexion',
      schoolYear: 'Année scolaire',
      consentDeniedReason: 'Raison de refus de consentement',
      consentFormName: 'Formulaire de consentement',
      surveysCompletedAt: 'Temps terminé',
      surveysStarted: 'Questionnaire commencé',
    },
    updateFailed: "L'utilisateur n'a pas pu être modifié",
    updateSuccess: "L'utilisateur a été modifié avec succès",
  },
  participationTypes: {
    control: 'Contrôle',
    experimental: 'Expérimentale',
  },
  passwordField: {
    changePasswordVisibility: 'Changez la visibilité du mot de passe',
  },
  routes: {
    adminRoutes: {
      CONFIGURATION: 'Configuration',
      SURVEYS: 'Questionnaires',
      HOME: 'Accès',
    },
    publicRoutes: {
      ADMIN_LOGIN: 'Connexion des administrateurs',
      HOME: 'Accueil',
    },
  },
  schoolPrograms: {
    enriched: 'Enrichi',
    regular: 'Régulier',
    special_education: 'Adaptation scolaire',
  },
  schoolTypes: {
    elementary: 'Primaire',
    high_school: 'Secondaire',
  },
  schools: {
    createFailed: "L'école n'a pas pu être créée",
    createSuccess: "L'école a été créée avec succès",
    deleteFailed: "L'école n'a pas pu être supprimée",
    deleteSuccess: "L'école a été supprimée avec succès",
    import: {
      title: "Importation d'écoles",
    },
    importFailed: 'Certaines lignes sont invalides',
    importSuccess: 'Toutes les écoles ont été importées avec succès',
    updateFailed: "L'école n'a pas pu être modifiée",
    updateSuccess: "L'école a été modifiée avec succès",
  },
  sections: {
    create: {
      title: "Création d'une section",
    },
    createFailed: "La section n'a pas pu être créée",
    createSuccess: 'La section a été créé avec succès',
    deleteFailed: "La section n'a pas pu être supprimée",
    deleteSuccess: 'La section a été supprimé avec succès',
    edit: {
      title: 'Modification de la section',
    },
    form: {
      description: 'Description',
      page: 'Page',
      sequenceNumber: 'Ordre',
      title: 'Titre',
    },
    updateFailed: "La section n'a pas pu être modifiée",
    updateSuccess: 'La section a été modifié avec succès',
  },
  themes: {
    create: {
      title: "Création d'un thème",
    },
    createFailed: "Le thème n'a pas pu être créé",
    createSuccess: 'Le thème a été créé avec succès',
    deleteFailed: "Le thème n'a pas pu être supprimé",
    deleteSuccess: 'Le thème a été supprimé avec succès',
    edit: {
      title: 'Modification du thème',
    },
    form: {
      backgroundImage: 'Image de fond',
      hexColor: 'Couleur hexadécimale',
      image1: 'Image 1',
      image2: 'Image 2',
      logo: 'Logo',
      name: 'Nom',
    },
    updateFailed: "Le thème n'a pas pu être modifié",
    updateSuccess: 'Le thème a été modifié avec succès',
  },
  userDetails: {
    confirm: 'Confirmer',
    save: 'Sauvegarder',
    student: {
      birthDate: 'Date de naissance',
      firstName: 'Prénom',
      groupName: 'Groupe-Classe',
      lastName: 'Nom de famille',
      permanentCode: 'Code permanent',
      schoolLevel: 'Niveau scolaire',
      schoolProgram: 'Programme scolaire',
    },
    parent: {
      unknownPermanentCode: 'Code permanent inconnu',
      birthDate: 'Date de naissance de l’enfant',
      firstName: "Prénom de l'enfant",
      groupName: 'Groupe-Classe',
      lastName: "Nom de famille de l'enfant",
      permanentCode: 'Code permanent',
      schoolLevel: 'Niveau scolaire',
      schoolProgram: 'Programme scolaire',
    },
  },
  userValidation: {
    animationTownName: "Ville du lieu de l'animation",
    birthDate: 'Date de naissance',
    confirm: 'Confirmer',
    firstName: 'Prénom',
    groupName: 'Groupe',
    institutionName: 'Nom du CI(U)SSS',
    lastName: 'Nom de famille',
    permanentCode: 'Code permanent',
    schoolLevelName: 'Niveau scolaire',
    schoolName: 'École',
    schoolProgramName: 'Programme scolaire',
  },
  stepName: {
    downloadUserConsents: 'Téléchargement des consentements',
    userValidation: 'Validation',
    userDetails: 'Information additionnelle',
    surveys: 'Questionnaires',
    unConfiguredSurveys: 'Questionnaires non configurés',
    surveysCompleted: 'Questionnaires terminés',
    connectionType: 'La page de connexion',
    consentForm: 'Formulaire de consentement',
    consentDenied: 'Consentement refusé',
    consentDeniedByParent: 'Consentement refusé par le parent',
    consentGiven: 'Consentement donné',
    missingParentalConsent: 'Consentement du parent manquant',
    deniedParentalConsent: 'Consentement refusé pour votre enfant',
    childOldEnoughToConsent: 'Enfant assez vieux pour donner son consentement',
  },
};
