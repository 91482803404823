import Button from 'src/components/Button';
import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';
import ReactHtmlParser from 'react-html-parser';

import locale from 'src/locale';

import Checkbox from 'src/components/Checkbox';
import css from './css';
import { Schema } from './form/schema';

type Props = {
  loading: boolean;
  consentFormHtml: string;
  associatedUserConsentFormHtml: string | null;
  denyConsentForm: () => void;
  acceptConsentForm: () => void;
  control: Control<Schema, any>;
  consentFormRead: boolean;
};

export default (props: Props) => {
  const {
    loading,
    control,
    denyConsentForm,
    acceptConsentForm,
    consentFormHtml,
    associatedUserConsentFormHtml,
    consentFormRead,
  } = props;

  return (
    <form>
      <div className={css.consentForm}>{ReactHtmlParser(consentFormHtml)}</div>

      {associatedUserConsentFormHtml ? (
        <div>
          <Checkbox
            name="associatedConsentFormRead"
            control={control}
            label={locale.consentForm.acceptParentConsentForm}
          />

          <h2 className={css.title}>
            Formulaire de consentement pour l'enfant
          </h2>

          <div className={css.consentForm}>
            {ReactHtmlParser(associatedUserConsentFormHtml)}
          </div>
        </div>
      ) : null}

      <Checkbox
        name="consentFormRead"
        control={control}
        label={locale.consentForm.consentFormRead}
      />

      {associatedUserConsentFormHtml ? (
        <Checkbox
          name="acceptConsentFormForChild"
          control={control}
          label={locale.consentForm.acceptConsentFormForChild}
        />
      ) : null}

      <div className={css.row}>
        <TextField
          name="phoneNumber"
          control={control}
          label={locale.consentForm.phoneNumber}
        />

        <TextField
          name="address"
          control={control}
          label={locale.consentForm.address}
        />
      </div>

      <div className={css.row}>
        <Button color="error" loading={loading} onClick={denyConsentForm}>
          {locale.consentForm.denyConsentForm}
        </Button>

        <Button
          color="success"
          loading={loading}
          onClick={acceptConsentForm}
          disabled={!consentFormRead}
          tooltip={
            consentFormRead
              ? ''
              : 'Vous devez lire le formulaire de consentement avant de pouvoir accepter.'
          }
        >
          {locale.consentForm.acceptConsentForm}
        </Button>
      </div>
    </form>
  );
};
