import axios from 'src/utils/axios';
import { Theme } from '../themes';
import { AllStep } from '../stepTypes';
import { PageBreadcrumbs } from '../pages';

import type {
  ConnectionTypeStepData,
  ConnectionTypeStep,
} from './connectionTypes';
import type { UserDetailsStepData } from './userDetails';
import type { UserValidationStepData } from './userValidation';
import { School } from '../schools';
import { UserMeasurement } from './userMeasurement';

export * from './userValidation';
export * from './user';
export * from './connectionTypes';
export * from './userDetails';

export type AccessType = 'main' | 'parent' | 'school';

export type ConsentFormStep = {
  name:
    | 'consentForm'
    | 'consentDenied'
    | 'consentDeniedByParent'
    | 'consentGiven'
    | 'missingParentalConsent'
    | 'deniedParentalConsent'
    | 'childOldEnoughToConsent';
};

export type RegularStep = {
  name:
    | 'downloadUserConsents'
    | 'userValidation'
    | 'userDetails'
    | 'surveys'
    | 'unConfiguredSurveys'
    | 'surveysCompleted';
};

export type Step = RegularStep | ConnectionTypeStep | ConsentFormStep;

export type ConsentFormStepData = {
  name: 'consentForm';
  data: {
    userMeasurement: UserMeasurement;
    consentFormHtml: string;
    associatedUserConsentFormHtml: string | null;
    details: {
      phoneNumber: string | null;
      address: string | null;
    };
  };
};
export type MissingParentalConsentStepData = {
  name: 'missingParentalConsent';
  data: {
    userMeasurement: UserMeasurement;
  };
};
export type DeniedParentalConsentStepData = {
  name: 'deniedParentalConsent';
  data: {
    userMeasurement: UserMeasurement;
  };
};
export type ChildOldEnoughToConsentStepData = {
  name: 'childOldEnoughToConsent';
  data: {
    userMeasurement: UserMeasurement;
  };
};
export type ConsentDeniedByParentStepData = {
  name: 'consentDeniedByParent';
  data: {
    userMeasurement: UserMeasurement;
  };
};
export type ConsentGivenStepData = {
  name: 'consentGiven';
  data: {
    userMeasurement: UserMeasurement;
  };
};
export type ConsentDeniedStepData = {
  name: 'consentDenied';
  data: {
    userMeasurement: UserMeasurement;
  };
};
export type SurveysStepData = {
  name: 'surveys';
  data: {
    userMeasurement: UserMeasurement;
    limeSurveyToken: string;
    limeSurveyId: number;
  };
};
export type UnConfiguredSurveysStepData = {
  name: 'unConfiguredSurveys';
  data: {
    userMeasurement?: UserMeasurement;
  };
};
export type SurveysCompletedStepData = {
  name: 'surveysCompleted';
  data: {
    userMeasurement?: UserMeasurement;
  };
};
export type DownloadUserConsentsStepData = {
  name: 'downloadUserConsents';
  data: {
    school: School;
    userCount: number;
  };
};
export type StepData =
  | ConnectionTypeStepData
  | UserValidationStepData
  | UserDetailsStepData
  | ConsentFormStepData
  | MissingParentalConsentStepData
  | DeniedParentalConsentStepData
  | ChildOldEnoughToConsentStepData
  | ConsentDeniedByParentStepData
  | ConsentGivenStepData
  | ConsentDeniedStepData
  | SurveysStepData
  | UnConfiguredSurveysStepData
  | SurveysCompletedStepData
  | DownloadUserConsentsStepData;

export type StepDataResponse = {
  appAccessId: number;
  previousStep?: Step;
  prefetch: StepData;
};

export const getStepData = (appAccessLink: string, stepName: AllStep) =>
  axios.get<StepDataResponse>(
    `api/appAccesses/stepData?appAccessLink=${appAccessLink}&stepName=${stepName}`
  );

export type HomeAppAccess = {
  appAccessId: number;
  theme?: Theme;
  hasConnectionType: boolean;
  accessType: AccessType;
  title: string;
  breadcrumbs: PageBreadcrumbs;
  parentPageId: number;
};

export type FirstStep = {
  step: Step;
};

export const getAppAccessPrefetchData = (appAccessLink: string) =>
  axios.get<HomeAppAccess>(
    `api/appAccesses/prefetchData?appAccessLink=${appAccessLink}`
  );

export const getFirstStep = (appAccessId: string, accessType: string) =>
  axios.get<FirstStep>(
    `api/appAccesses/firstStep?appAccessId=${appAccessId}&accessType=${accessType}`
  );

export const registerSurveyInProgress = (appAccessId: number) =>
  axios.post<boolean>(`api/appAccesses/surveyInProgress`, {
    appAccessId,
  });
