import React from 'react';
import { MissingParentalConsentStepData } from 'src/requests/stepData';
import Button from 'src/components/Button';
import locale from 'src/locale';
import css from './css';
import { AccessFlowComponentProps } from '../AccessFlowWrapper/Interface';

type Props = AccessFlowComponentProps & {
  // eslint-disable-next-line react/no-unused-prop-types
  stepData: {
    prefetch: MissingParentalConsentStepData;
  };
};

export default (props: Props) => {
  const { leaveAccess } = props;

  return (
    <div>
      <h2 className={css.title}>En attente de consentement parental</h2>

      <p>
        Malheureusement, nous n'avons pas obtenu le consentement de ton parent
        pour ta participation à cette évaluation. Tu n'es donc pas autorisé(e) à
        compléter les questionnaires.
      </p>

      <div className={css.actions}>
        <Button component="button" onClick={leaveAccess}>
          {locale.general.leave}
        </Button>
      </div>
    </div>
  );
};
